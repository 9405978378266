<template>
  <v-card elevation="0">
    <slot :is-form-dirty="isFormDirty">
      <validation-observer ref="observer">
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required|min:1"
              :name="$t('shipments.client')"
            >
              <v-autocomplete
                ref="client"
                v-model="item.client"
                :items="clients"
                item-text="name"
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                :label="$t('shipments.client')"
                :hint="$t('select-hint')"
                persistent-hint
                dense
                :disabled="!canChangeClient() || disabled"
                :error-messages="errors"
                @keydown.esc="onCancel"
              />
            </validation-provider>
          </v-col>

          <v-col cols="6" sm="3" md="3">
            <validation-provider
              v-slot="{ errors }"
              rules="required|min:1"
              :name="$t('status')"
            >
              <v-select
                v-model="item.status"
                :items="statuses"
                :menu-props="{ maxHeight: '400' }"
                :label="$t('status')"
                :hint="$t('select-hint')"
                persistent-hint
                dense
                :error-messages="errors"
                @keydown.esc="onCancel"
              />
            </validation-provider>
          </v-col>

          <v-col>
            <date-time-picker
              v-model="item.shipping_date"
              :label="$t('shipments.shipping-date')"
              :clearable="true"
              :disabled="disabled"
            />
          </v-col>

          <v-col>
            <date-time-picker
              v-model="item.delivery_date"
              :label="$t('shipments.delivery-date')"
              :clearable="true"
              :disabled="disabled"
            />
          </v-col>
        </v-row>

        <div v-if="client">
          <v-row v-if="addresses.length > 0">
            <v-col cols="4">
              <v-treeview
                :disabled="disabled"
                dense
                activatable
                :active.sync="addressSelection"
                :items="addresses"
                open-all
                hoverable
                @keydown.esc="onCancel"
              >
                <template #prepend="{ item }">
                  <v-icon v-if="!item.children">
                    mdi-city
                  </v-icon>
                </template>
              </v-treeview>
            </v-col>
            <v-col cols="8">
              <address-card
                v-if="selected"
                :disabled="disabled"
                :address="selected"
                :is-new="false"
                :readonly="disabled"
                @cancel="onCancel"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            {{ $t('shipments.no-address') }}
          </v-row>
        </div>
      </validation-observer>
    </slot>
    <v-card-actions>
      <v-btn
        v-if="isFormDirty()"
        color="blue darken-1"
        text
        @click="onSaveAndContinue()"
      >
        {{ $t('shipments.save-to-continue') }}
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="isFormDirty()"
        color="blue darken-1"
        text
        @click="onCancel()"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn v-else color="blue darken-1" text @click="onCancel()">
        {{ $t('close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ShipmentService from '@/services/ShipmentService.js';
import DateTimePicker from '../../../../components/base/DateTimePicker.vue';
import shipmentMixin from './shipmentMixin.js';

export default {
  components: {
    DateTimePicker,
    addressCard: () => import('./addressCard.vue')
  },
  mixins: [shipmentMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    clients: {
      type: Array,
      required: true
    }
  },
  data: function() {
    return {
      item: Object.assign({}, this.value),
      addressSelection: []
    };
  },
  computed: {
    isNew() {
      return !this.item?.id;
    },
    selected() {
      if (!this.addresses.length) return undefined;
      const id = this.addressSelection[0];
      return this.client?.addresses.find(a => a.id === id);
    },
    addresses() {
      let all = this.client?.addresses || [];
      let filtered = all.filter(
        a => a.active && a.types.find(t => t == 'Delivery')
      );
      return filtered;
    },
    client() {
      console.log('looking for: ', this.item.client);
      let c = this.clients.find(c => c.id == this.item.client);
      console.log('found client:', c);
      return c;
    },
    disabled() {
      return this.item.status != 'in preparation';
    }
  },
  watch: {
    value: {
      async handler(val) {
        this.item = Object.assign({}, val);
      },
      deep: true
    },
    client() {
      this.selectAddress();
    },
    clients: {
      async handler() {
        this.selectAddress();
      },
      deep: true
    }
  },
  async activated() {
    console.log('shipmentForm.activated()');
    await this.init();
  },
  methods: {
    init() {
      this.item = Object.assign({}, this.value);
      this.focusInput();
    },
    focusInput() {
      this.$refs.client.focus();
    },
    async onSave() {
      if (await this.save()) {
        this.$emit('close');
      }
    },
    async onSaveAndContinue() {
      await this.save();
    },
    async save() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        console.log('validation failed');
        return;
      }
      if (!this.addressSelection.length) {
        this.showError(this, 'Select address');
        return false;
      }

      try {
        let obj = Object.assign({}, this.item);
        obj.address_id = this.addressSelection.length
          ? this.addressSelection[0]
          : '';

        if (obj.shipping_date) {
          obj.shipping_date = obj.shipping_date.toISOString();
        }
        if (obj.delivery_date) {
          obj.delivery_date = obj.delivery_date.toISOString();
        }

        let shipment = null;
        if (this.isNew) {
          let result = await ShipmentService.postShipment(obj);
          shipment = result.data;
          shipment.shipping_date = this.dateFromISO8601(shipment.shipping_date);
          shipment.delivery_date = this.dateFromISO8601(shipment.delivery_date);
          console.log('shipment_added: ', shipment);
          this.$root.$emit('shipment_added', shipment); // use central event hub to notify siblign container
          // this.$emit("save", shipment)
        } else {
          let result = await ShipmentService.patchShipment(obj);
          shipment = result.data;
          shipment.shipping_date = this.dateFromISO8601(shipment.shipping_date);
          shipment.delivery_date = this.dateFromISO8601(shipment.delivery_date);
          console.log('shipment_updated: ', shipment);
          this.$root.$emit('shipment_updated', shipment); // use central event hub to notify siblign container
          // this.$emit("save", shipment)
        }
        this.$emit('save', shipment);
        return shipment;
      } catch (err) {
        this.showError(this, err);
      }

      return null;
    },
    selectAddress() {
      if (this.addresses.length > 0) {
        if (this.item.address_id) {
          this.addressSelection = [this.item.address_id];
        } else {
          this.addressSelection = [this.addresses[0].id];
        }
      }
    },
    async addressClicked(item) {
      console.log('clicked:', item);
      const result = await this.$refs.observer.validate();
      if (result) {
        this.addressSelection = [item.id];
        this.item.address_id = item.id;
      }
    },
    async onCancel() {
      this.$refs.observer.reset();
      this.$emit('close');
    },
    canChangeClient() {
      if (this.item.parcel_count) return false;
      return true;
    },
    isAddressSelected() {
      return this.addressSelection.length > 0;
    },
    isFormDirty() {
      if (this.isNew) {
        console.log('dirty - isNew');
        return true;
      }
      if (this.item.client != this.value.client) {
        console.log('dirty - client changed');
        return true;
      }
      if (this.item.status != this.value.status) {
        console.log('dirty - status changed');
        return true;
      }
      if (
        Math.floor(this.item.shipping_date.getTime() / 1000) !=
        Math.floor(this.value.shipping_date.getTime() / 1000)
      ) {
        console.log('dirty - shipping_date changed');
        return true;
      }

      if (
        Math.floor(this.item.delivery_date.getTime() / 1000) !=
        Math.floor(this.value.delivery_date.getTime() / 1000)
      ) {
        console.log('dirty - delivery_date changed');
        return true;
      }
    }
  }
};
</script>

<style></style>
